var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pa-3 ma-10", attrs: { tile: "" } },
    [
      _c("v-card-title", [_vm._v(" ユーザープロフィール ")]),
      _vm._l(_vm.profileItems, function (item) {
        return _c(
          "div",
          { key: item.name },
          [
            _c("v-card-subtitle", [_vm._v(_vm._s(item.name))]),
            _c("v-card-text", [_vm._v(_vm._s(item.value))]),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }